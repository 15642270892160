html,
code,
body,
html * {
  font-family: "Roboto", sans-serif;
}

body {
  background-image: url(./assets/newHomeBg.png);
  background-size: cover;
  margin: 0;
  font-family: "Roboto", medium;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

:root {
  --font-size-header-xxxl: 150px;
  --font-size-header-xxl: 128px;
  --font-size-header-xl: 108px;
  --font-size-header-l: 96px;
  --font-size-header-m: 84px;
  --font-size-header-s: 72px;
  --font-size-header-xs: 48px;

  --font-size-paragraph-xxl: 28px;
  --font-size-paragraph-xl: 24px;
  --font-size-paragraph-l: 22px;
  --font-size-paragraph-m: 20px;
  --font-size-paragraph-s: 18px;

  --font-size-xl: 25px;
  --font-size-l: 20px;
  --font-size-m: 16px;
  --font-size-s: 14px;
  --font-size-xs: 10px;

  --logo-size-xl: 168px;
  --logo-size-l: 124px;
  --logo-size-m: 96px;
  --logo-size-s: 84px;
  --logo-size-xs: 72px;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 15px;
  background-color: lightgrey;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgb(0, 113, 188);
  border: 3px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-track {
  background-color: rgb(186, 217, 237);
}

@font-face {
  font-family: "BebasNeue";
  src: url(./assets/BebasNeue-Bold.ttf);
}

@font-face {
  font-family: "Roboto";
  src: url(./assets/Roboto-Regular.ttf);
}
