.logo {
  padding-top: 1rem;
}

.icon {
  color: rgb(38, 127, 193);
  cursor: pointer;
  margin: 10px;
}

.navAndLogo {
  padding-inline-start: 3em;
  display: flex;
  justify-content: space-between;
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 168px;
}

nav a {
  text-decoration: none;
  text-transform: uppercase;

  font-family: "Roboto" medium;
  font-weight: 600;

  letter-spacing: 0px;
  color: #303030;
  opacity: 1;
  margin: auto 2em auto 1em;
}
.mobileMenuHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.mobileMenuHeader img {
  margin: 0px 0px 5px 0px;
  padding: 0px;

  width: var(--logo-size-s);
  height: var(--logo-size-s);
}

.mobileMenuHeader h3 {
  margin-block: 1rem;
  color: #303030;
  font-family: "BebasNeue", "cursive";
  font-size: 175%;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}
.mobileMenuHeader h3::after {
  margin-top: 0.7rem;
  display: block;
  content: "";
  height: 1.5px;
  width: 88%;
  margin-inline: auto;
  background-color: #303030;
}

/*
/* Smartphone devices (576px and down) */
@media (max-width: 576px) {
  .active {
    transform: translateX(+65vw);
  }

  .nav {
    padding-top: 20px;
    width: 65vw;
    flex-direction: column;
    justify-content: start;
    position: fixed;
    left: -65vw;
    max-height: none;
    height: 100vh;
    background-color: rgb(154, 200, 230);
    padding-inline: auto;
    z-index: 2;
    transition: 1s;
  }

  .nav a {
    text-transform: none;
    margin-bottom: 1em;
  }
  .navItem {
    width: 11rem;
    white-space: nowrap;
    margin-block: 1rem;
    padding-right: 0.5rem;
  }

  .navAndLogoMobile {
    display: flex;
    width: 100%;
  }
  .icon {
    position: absolute;
    align-self: center;
    padding-inline-start: 1rem;
  }
  .closeIcon {
    align-self: flex-end;
    cursor: pointer;
    height: 20px;
    margin-right: 20px;
  }
  .navAndLogo {
    padding: 0px;
  }
  .logo {
    margin-inline: auto;
    width: var(--logo-size-s);
    height: var(--logo-size-s);
  }
  .customSelectMobile {
    position: fixed;
    top: 30px;
    right: 10px;
  }

  .dropDown {
    right: 0.7rem;
  }
}

/* Small devices (tablets 576px-768px) */
@media (min-width: 576px) and (max-width: 768px) {
  .logo {
    width: var(--logo-size-xs);
    height: var(--logo-size-xs);
  }
  nav {
    font-size: var(--font-size-xs);
    line-height: var(--font-size-xs);
  }
  nav a {
    font-size: var(--font-size-xs);
    line-height: var(--font-size-xs);
  }
  .dropDown {
    right: 0.7rem;
  }
}

/* Medium devices (desktops, 768px-1080px) */
@media (min-width: 768px) and (max-width: 1080px) {
  .logo {
    width: var(--logo-size-s);
    height: var(--logo-size-s);
  }
  nav {
    font-size: var(--font-size-s);
    line-height: var(--font-size-s);
  }
  nav a {
    font-size: var(--font-size-s);
    line-height: var(--font-size-s);
  }
}

/* Large devices (desktops, 1080px-1366px) */
@media (min-width: 1080px) and (max-width: 1366px) {
  .logo {
    width: var(--logo-size-m);
    height: var(--logo-size-m);
  }
  nav {
    font-size: var(--font-size-paragraph-m);
    line-height: var(--font-size-paragraph-m);
  }
  nav a {
    font-size: var(--font-size-paragraph-m);
    line-height: var(--font-size-paragraph-m);
  }
}

/* Extra large devices (desktops, 1366px-1680px) */
@media (min-width: 1366px) and (max-width: 1680px) {
  .logo {
    width: var(--logo-size-l);
    height: var(--logo-size-l);
  }
  nav {
    font-size: var(--font-size-paragraph-l);
    line-height: var(--font-size-paragraph-l);
  }
  nav a {
    font-size: var(--font-size-paragraph-l);
    line-height: var(--font-size-paragraph-l);
  }
}

/* Extra extra large devices (desktops, 1680px and up) */
@media (min-width: 1680px) {
  .logo {
    width: var(--logo-size-xl);
    height: var(--logo-size-xl);
  }
  nav {
    font-size: var(--font-size-paragraph-xxl);
    line-height: var(--font-size-paragraph-xxl);
  }
  nav a {
    font-size: var(--font-size-paragraph-xxl);
    line-height: var(--font-size-paragraph-xxl);
  }
}
