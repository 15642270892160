.container {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: top;
  min-height: 300px;
  padding-block: 2.5rem;
}
.content {
  width: 75%;
}

.languages {
  display: flex;
  justify-content: space-between;
}
.languages > div {
  justify-self: center;
  background-size: cover;
  border-radius: 100%;
  width: 128px;
  height: 128px;
  position: relative;
  font-weight: 700;
  background-position: center;
}

.languages > div > span {
  position: absolute;
  bottom: -3rem;
  left: 50%;
  transform: translatex(-50%);
}

@media (max-width: 412px) {
  .container {
    height: fit-content;
  }

  .languages {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 4rem;
    grid-column-gap: 2rem;
  }
  .languages > div {
    width: 4rem;
    height: 4rem;
    font-family: "Roboto" medium;
    font-weight: 700;
  }
  .languages > div > span {
    bottom: -2rem;
  }
}

@media (min-width: 412px) and (max-width: 576px) {
  .container {
    height: fit-content;
  }

  .languages {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 4rem;
    grid-column-gap: 2rem;
  }
  .languages > div {
    width: 5rem;
    height: 5rem;
    font-family: "Roboto" medium;
    font-weight: 700;
    margin-block: 0px;
  }
  .languages > div > span {
    bottom: -2rem;
  }
}

/* Small devices (tablets 576px-768px) */
@media (min-width: 576px) and (max-width: 768px) {
  .languages > div {
    margin-block: 0px;
  }
  .container {
    height: fit-content;
  }
  .languages > div {
    font-size: var(--font-size-s);
    width: var(--logo-size-xs);
    height: var(--logo-size-xs);
  }
}

@media (min-width: 768px) and (max-width: 1080px) {
  .container {
    height: fit-content;
  }
  .languages > div {
    font-size: var(--font-size-paragraph-s);
    width: var(--logo-size-s);
    height: var(--logo-size-s);
  }
}

/* Large devices (desktops, 1080px-1336px) */
@media (min-width: 1080px) and (max-width: 1366px) {
  .languages > div {
    font-size: var(--font-size-paragraph-m);
    width: var(--logo-size-m);
    height: var(--logo-size-m);
    margin-block: 2rem;
  }
}

/* Extra extra large devices (desktops, 1680px and up) */
@media (min-width: 1366px) and (max-width: 1680px) {
  .languages > div {
    font-size: var(--font-size-paragraph-l);
    width: var(--logo-size-l);
    height: var(--logo-size-l);
    margin-block: 2rem;
  }
}

@media (min-width: 1680px) {
  .languages > div {
    font-size: var(--font-size-paragraph-xl);
    width: var(--logo-size-l);
    height: var(--logo-size-l);
  }
}

@media (min-width: 1920px) {
  .languages > div {
    font-size: var(--font-size-paragraph-xxl);
    width: var(--logo-size-xl);
    height: var(--logo-size-xl);
  }
}
