.container {
  height: 37.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 550px;
}
.frame {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 90%;
  width: 60%;
  border: 7px solid white;
}

.frame h1 {
  font-family: "BebasNeue", cursive;
  color: rgb(46, 47, 51);
  font-size: var(--font-size-header-l);
  text-align: center;
  text-transform: uppercase;
  margin-top: 1rem;
  margin-bottom: 0px;
}
.paragraph {
  margin: 0px;
  font-family: "Roboto", sans-serif;
  color: #303030;
  font-size: var(--font-size-paragraph-xs);
  width: clamp(250px, 80%, 700px);
  text-align: center;
}

.image {
  position: absolute;
  bottom: 0;
  object-fit: contain;
  object-position: bottom;
  height: 80%;
  width: 95%;
}

@media (max-width: 456px) {
  .container {
    height: 100%;
    margin-inline: 5%;
    padding-inline: 8px;
    min-height: 300px;
    margin-bottom: 20px;
  }
  .frame {
    height: 385px;
    border: 3px solid white;
    width: 100%;
  }
  .frame h1 {
    margin-block: 5px;
    font-size: var(--font-size-header-xs);
  }
  .paragraph {
    font-size: 10px;
  }
  .image {
    max-height: 60%;
  }
}
@media (max-width: 576px) {
  .container {
    height: 100%;
    margin-inline: 5%;
    padding-inline: 8px;
    min-height: 300px;
    margin-bottom: 20px;
  }
  .frame {
    height: 285px;
    border: 3px solid white;
    width: 100%;
  }
  .frame h1 {
    margin-block: 5px;
    font-size: var(--font-size-header-xs);
  }
  .paragraph {
    font-size: 10px;
  }
  .image {
    max-height: 67%;
  }
}

/* Small devices (tablets 576px-768px) */
@media (min-width: 576px) and (max-width: 768px) {
  .frame h1 {
    font-size: var(--font-size-header-xs);
  }
  .frame {
    border: 4px solid white;
    height: 66%;
    width: 75%;
  }
}

/* Medium devices (desktops, 768px-1080px) */
@media (min-width: 768px) and (max-width: 1080px) {
  .frame h1 {
    font-size: var(--font-size-header-s);
  }
  .frame {
    width: 75%;

    border: 4px solid white;
    height: 75%;
  }
}

/* Large devices (desktops, 1080px-1336px) */
@media (min-width: 1080px) and (max-width: 1366px) {
  .frame h1 {
    font-size: var(--font-size-header-m);
  }
  .frame {
    border: 5px solid white;
  }
  .image {
    height: 75%;
  }
}

/* Extra extra large devices (desktops, 1680px and up) */
@media (min-width: 1366px) and (max-width: 1680px) {
  .image {
    height: 75%;
  }
  .frame h1 {
    font-size: var(--font-size-header-l);
  }
}

@media (min-width: 1680px) {
  .frame h1 {
    font-size: var(--font-size-header-xl);
  }
}

@media (min-width: 1920px) {
  .frame h1 {
    font-size: var(--font-size-header-xxl);
  }
}
