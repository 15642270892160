.container {
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 420px;
  padding-block: 2.5rem;
}

.videosContainer {
  border: 1px solid red;
  z-index: 0;
  display: flex;
  width: 85%;
  align-items: center;
  justify-content: center;
  border: 0px solid white;
  border-radius: 15px;
  padding-block: 5rem;
  padding-inline: 2rem;
}

.button {
  cursor: pointer;
  border: none;
  position: absolute;
  top: 33%;
  box-shadow: none;
  background-color: transparent;
  color: #267fc1;
}
.right {
  right: 0%;
}

.buttonIcon {
  width: 25px;
}

@media (max-width: 650px) {
  .videosContainer {
    height: 0px;
  }
  .container {
    min-height: 200px;
    height: 300px;
  }
  .right {
    right: -20px;
  }
  .left {
    left: -20px;
  }
}

@media (max-width: 950px) {
  .right {
    right: -30px;
  }
  .left {
    left: -30px;
  }
}
