.toTheTop {
  color: rgb(38, 127, 193);

  cursor: pointer;
  position: fixed;
  width: 30px;
  height: 30px;
  right: 25px;
  bottom: 25px;
  z-index: 2;
  animation: bounce 1s;
}
.downArrow {
  color: rgb(38, 127, 193);

  cursor: pointer;
  position: fixed;
  width: 32px;
  height: 32px;
  bottom: 2.5%;
  z-index: 1;
  padding-inline: calc(50% - 16px);
  display: none;
}

@keyframes bounce {
  0% {
    scale: 100%;
  }
  50% {
    scale: 200%;
  }
  100% {
    scale: 100%;
  }
}

.contact-icon {
  color: rgb(38, 127, 193);
  height: 3rem;
}
.social-icon {
  color: rgb(38, 127, 193);
  height: 3rem;
  cursor: pointer;
}

.menu-icon {
  width: 1.5rem;
}

@media (max-width: 576px) {
  .toTheTop {
    display: none;
  }
  .downArrow {
    display: block;
  }
  .contact-icon {
    height: 2rem;
  }
  .social-icon {
    height: 2rem;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .contact-icon {
    height: 2rem;
  }
  .social-icon {
    height: 2rem;
  }
}

.heading {
  margin-top: 0px;
  margin-bottom: 30px;
  font-family: "BebasNeue", cursive;
  text-transform: uppercase;
  color: rgb(46, 47, 51);
  font-size: var(--font-size-header-l);
  text-align: center;
}

@media (max-width: 412px) {
  .heading {
    margin-block: 3rem;
    font-size: var(--font-size-header-xs);
  }
}
@media (min-width: 412px) and (max-width: 576px) {
  .heading {
    margin-top: 1rem;
    font-size: var(--font-size-header-xs);
  }
  .ourWork {
    margin-block: 3rem;
  }
}

/* Small devices (tablets 576px-768px) */
@media (min-width: 576px) and (max-width: 768px) {
  .heading {
    font-size: var(--font-size-header-xs);
  }
}

@media (min-width: 768px) and (max-width: 1080px) {
  .heading {
    font-size: var(--font-size-header-s);
  }
}

/* Large devices (desktops, 1080px-1336px) */
@media (min-width: 1080px) and (max-width: 1366px) {
  .heading {
    font-size: var(--font-size-header-m);
  }
}

/* Extra extra large devices (desktops, 1680px and up) */
@media (min-width: 1366px) and (max-width: 1680px) {
  .heading {
    font-size: var(--font-size-header-l);
  }
}

@media (min-width: 1680px) {
  .heading {
    font-size: var(--font-size-header-xl);
  }
}

@media (min-width: 1920px) {
  .heading {
    font-size: var(--font-size-header-xxl);
  }
}
