.video {
  border: none;
  border-radius: 40px;
  width: 420px;
  height: 240px;
}

@media (max-width: 650px) {
  .video {
    width: 200px;
    height: 150px;
  }
}
