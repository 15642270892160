.downArrow {
  display: none;
}

.container {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 600px;
  overflow: hidden;
}

.contentBox {
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  margin: 5%;
  display: flex;
  padding: 1rem;
  max-height: 100%;
}

.text {
  width: 100%;
}

.image {
  background-image: url(../assets/Group_230.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: stretch;
  height: auto;
}

.text h1 {
  font-family: "BebasNeue", "cursive";
  color: #303030;
  line-height: 100px;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  margin-top: 0px;
}

.text h1::after {
  display: block;
  content: "";
  width: 44%;
  height: 3px;
  background: black;
  position: absolute;
  bottom: -10px;
  left: 28%;
  right: 28%;
}

.text p {
  font-family: "Roboto", sans-serif;
  margin-top: 0px;
  text-align: center;
  padding-inline: 4rem;
  color: #303030;
}

/* Smartphone devices (576px and down)  */
@media (max-width: 576px) {
  .downArrow {
    display: block;
    width: 64px;
    height: 64px;

    margin: auto;
    position: fixed;
    bottom: 10%;

    cursor: pointer;
    color: rgb(38, 127, 193);
  }

  .container {
    min-height: 300px;
  }

  .content {
    height: 100%;
    margin: 5%;
    display: flex;
    flex-direction: column;
    justify-content: normal;
    align-items: center;
    padding: 1rem;
    max-height: none;
  }
  .image {
    background-position: top;
    min-height: 180px;
  }
  .text h1::after {
    display: block;
    content: "";
    width: 44%;
    height: 1px;
    background: transparent;
    position: absolute;
    bottom: -10px;
    left: 28%;
    right: 28%;
  }

  .text h1 {
    margin-top: 2.5rem;
    line-height: 50px;
    font-size: var(--font-size-header-xs);
  }
  .text p {
    font-size: var(--font-size-m);
    padding-inline: 0em;
    margin-block: 2rem;
  }
}

/* Small devices (tablets 576px-768px) */
@media (min-width: 576px) and (max-width: 768px) {
  .text h1 {
    line-height: 60px;
    font-size: var(--font-size-header-xs);
  }
  .text p {
    font-size: var(--font-size-xs);
    padding-inline: 1em;
  }
}
/* Medium devices (desktops, 768px-1080px) */
@media (min-width: 768px) and (max-width: 1080px) {
  .text h1 {
    line-height: 80px;
    font-size: var(--font-size-header-s);
  }
  .text p {
    font-size: var(--font-size-s);
    padding-inline: 2em;
  }
}

/* Large devices (desktops, 1080px-1336px) */
@media (min-width: 1080px) and (max-width: 1366px) {
  .text h1 {
    font-size: var(--font-size-header-m);
  }
  .text p {
    font-size: var(--font-size-paragraph-s);
    padding-inline: 2em;
  }
}

/* Extra extra large devices (desktops, 1680px and up) */
@media (min-width: 1366px) and (max-width: 1680px) {
  .text h1 {
    font-size: var(--font-size-header-l);
  }
  .text p {
    font-size: var(--font-size-paragraph-m);
  }
}

@media (min-width: 1680px) {
  .text h1 {
    font-size: var(--font-size-header-xl);
  }
  .text p {
    font-size: var(--font-size-paragraph-xl);
  }
  .image {
    margin-inline-start: 5%;
  }
}

@media (min-width: 1920px) {
  .text h1 {
    font-size: var(--font-size-header-xxl);
  }
  .text p {
    font-size: var(--font-size-paragraph-xxl);
  }
  .image {
    margin-inline-start: 15%;
  }
}
