.container {
  width: fit-content;
  display: flex;
  gap: 2rem;
  padding-left: 30px;
  padding-right: auto;
  align-items: center;
  margin-bottom: 1rem;
  margin-right: 0px;
  height: max-content;
}
.details > h5 {
  color: #303030;
  font-weight: bold;
  align-self: center;
  padding-bottom: 0px;

  margin-bottom: 0.34rem;
  margin-top: 0px;
}
.details span {
  font-weight: light;
  margin: 0px;
  color: #303030;
  font-size: 1.5rem;
}
@media (max-width: 768px) {
  .icon {
    height: 1rem;
  }
  .container {
    gap: 1rem;
    margin-bottom: 0rem;
  }
  .details > h5 {
    font-weight: bold;
    font-size: var(--font-size-paragraph-s);
    align-self: center;
    padding-bottom: 0px;
    margin-bottom: 0rem;
  }
  .details span {
    font-size: var(--font-size-s);
  }
}

@media (min-width: 768px) and (max-width: 1080px) {
  .container {
    gap: 1rem;
    margin-bottom: 0rem;
  }
  .details > h5 {
    font-size: var(--font-size-paragraph-m);

    align-self: center;
    padding-bottom: 0px;
    margin-bottom: 0rem;
  }
  .details span {
    font-size: var(--font-size-paragraph-s);
  }
}

@media (min-width: 1080px) and (max-width: 1366px) {
  .container {
    gap: 1rem;
    margin-bottom: 0rem;
  }
  .details > h5 {
    font-size: var(--font-size-paragraph-l);
  }
  .details span {
    font-size: var(--font-size-paragraph-m);
  }
}

@media (min-width: 1366px) {
  .container {
    gap: 1rem;
    margin-bottom: 0rem;
  }
  .details > h5 {
    font-size: var(--font-size-paragraph-xl);
  }
  .details span {
    font-size: var(--font-size-l);
  }
}
